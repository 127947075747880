import React, { Component } from 'react';
import { Field, reduxForm } from 'redux-form'

import Text from './fields/text';
import Select from './fields/select';
import Section from './fields/section';

import {
    Container,
    Row,
    Col,
} from 'reactstrap';
import { API_ENDPOINT } from '../../constants';


const renderTextField = ({
    input,
    placeholder,
    label,
    meta: { touched, error },
}) => (

        <Text
            placeholder={placeholder}
            label={label}
            errorText={touched && error}
            error={touched && error}
            {...input}
        />
    )

const renderSelectField = ({
    input,
    placeholder,
    label,
    meta: { touched, error },
    children,
    additionalAction,
    scope,
}) => (

        <Select
            placeholder={placeholder}
            label={label}
            errorText={touched && error}
            error={touched && error}
            additionalAction={additionalAction}
            scope={scope}
            {...input}
            children={children}
        />
    )

const renderSectionField = ({
    input,
    meta: { touched, error },
}) => (

        <Section
            errorText={touched && error}
            error={touched && error}

            {...input}
        />
    )




class form extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            sections: [],
            parentElements: []
        }
    }

    componentDidMount() {

        fetch(API_ENDPOINT + '/admin/fetch/' + this.props.collection, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${localStorage.getItem('token')}`
            },
        }).then((res) => res.json()).then((result) => {
            console.log(result);
            this.setState({
                parentElements: result
            })
        })

    }

    render() {

        const { handleSubmit, pristine, reset, submitting } = this.props;
        console.log(this.props);

        return (
            <form onSubmit={handleSubmit}>
                <Row>
                    <Col lg="12" >
                        <Container fluid className="form-box">
                            <Row>
                                <Col lg="12">
                                    <h3 className="title">Ime stranice</h3>
                                    <h6 className="subtitle">Unesite ime stranice</h6>

                                </Col>
                                <Col lg="4" className="input-wrap">
                                    <Field
                                        name="name"
                                        component={renderTextField}
                                        label={"Ime stranice"}
                                        placeholder="Unesite ime stranice"

                                    ></Field>

                                </Col>
                                <Col lg="3" className="input-wrap">
                                    <Field
                                        name="alias"
                                        component={renderTextField}
                                        label={"Alias"}
                                        placeholder="Unesite alias"

                                    ></Field>

                                </Col>
                                <Col lg="2" className="input-wrap">
                                    <Field
                                        name="position"
                                        component={renderTextField}
                                        label={"Pozicija"}
                                        placeholder="Unesite poziciju"

                                    ></Field>

                                </Col>

                                <Col lg="3" className="input-wrap">
                                    <Field
                                        name="parent"
                                        component={renderSelectField}
                                        label={"Kategorija"}
                                        placeholder="Izaberite kategoriju"
                                    >
                                    {
                                        this.state.parentElements.map((item, idx) => {
                                            return (
                                                <option value={item._id}>{item.name}</option>
                                            )
                                        })
                                    }
                                    </Field>

                                </Col>


                            </Row>
                        </Container>
                    </Col>
                    <Field
                        name="sections"
                        component={renderSectionField}
                        
                    >
                    </Field>




                    <Col lg="12">
                        <button className="button">Save</button>

                    </Col>

                </Row>

            </form>
        )
    }
}

export default reduxForm({
    form: 'form'  // a unique identifier for this form
})(form)
