import React, { Component } from 'react';
import Isvg from 'react-inlinesvg';
import { Link } from 'react-router-dom';
import logo from '../assets/images/ee.png';
import menu from '../assets/svg/menu.svg';

import list from '../assets/svg/list.svg';
import add from '../assets/svg/add.svg';
import rocket from '../assets/svg/rocket.svg';
import mail from '../assets/svg/mail.svg';
import settings from '../assets/svg/settings.svg';
import exit from '../assets/svg/exit.svg';

import home from '../assets/svg/home.svg';

class Sidebar extends Component {

    constructor(props) {
        super(props);

        this.state = {
            _show: true
        };
    }




    render() {

        return (

            <div className={this.state._show ? `sidebar` : 'sidebar sidebar-hidden'}>
                <div className="top">
                    <div className="logo">
                        <img src={logo} />
                    </div>

                </div>

                <div className="items">
                <h6>POČETNA</h6>
                    <ul>

                        <li>
                            <Link to='/' className={this.props[0].location.pathname == '/' ? 'active' : null}>
                                <Isvg src={home} />
                                Uredi
                            </Link>
                        </li>
                        <li>
                            <Link to='/collection/slides' className={this.props[0].location.pathname == '/collection/slides' ? 'active' : null}>
                                <Isvg src={list} />
                                Svi slajdovi
                            </Link>
                        </li>


                        <li>
                            <Link to='/collection/slides/new' className={this.props[0].location.pathname == '/collection/slides/new' ? 'active' : null}>
                                <Isvg src={add} />
                                Dodaj slajd
                            </Link>
                        </li>




                    </ul>
                  
                    <h6>O NAMA</h6>
                    <ul>

                        <li>
                            <Link to='/collection/pages' className={this.props[0].location.pathname == '/collection/pages' ? 'active' : null}>
                                <Isvg src={list} />
                                Sve stranice
                            </Link>
                        </li>


                        <li>
                            <Link to='/collection/pages/new' className={this.props[0].location.pathname == '/collection/pages/new' ? 'active' : null}>
                                <Isvg src={add} />
                                Dodaj stranicu
                            </Link>
                        </li>


                    </ul>

                    <h6>NAŠE USLUGE</h6>
                    <ul>

                        <li>
                            <Link to='/collection/services' className={this.props[0].location.pathname == '/collection/services' ? 'active' : null}>
                                <Isvg src={list} />
                                Sve usluge
                            </Link>
                        </li>


                        <li>
                            <Link to='/collection/services/new' className={this.props[0].location.pathname == '/collection/services/new' ? 'active' : null}>
                                <Isvg src={add} />
                                Dodaj uslugu
                            </Link>
                        </li>


                    </ul>
                    <h6>NOVOSTI</h6>
                    <ul>

                        <li>
                            <Link to='/collection/news' className={this.props[0].location.pathname == '/collection/news' ? 'active' : null}>
                                <Isvg src={list} />
                                Sve novosti
                            </Link>
                        </li>


                        <li>
                            <Link to='/collection/news/new' className={this.props[0].location.pathname == '/collection/news/new' ? 'active' : null}>
                                <Isvg src={add} />
                                Dodaj novost
                            </Link>
                        </li>


                    </ul>


                    <h6>ZAPOSLENJE</h6>
                    <ul>
                        <li>
                            <Link to='/collection/employments' className={this.props[0].location.pathname == '/collection/employments' ? 'active' : null}>
                                <Isvg src={list} />
                                Sve stranice
                            </Link>
                        </li>
                        <li>
                            <Link to='/collection/employments/new' className={this.props[0].location.pathname == '/collection/employments/new' ? 'active' : null}>
                                <Isvg src={add} />
                                Dodaj stranicu
                            </Link>
                        </li>

                    </ul>


                    <h6>STRANICE</h6>
                    <ul>
                        <li>
                            <Link to='/collection/static' className={this.props[0].location.pathname == '/collection/static' ? 'active' : null}>
                                <Isvg src={list} />
                                Sve stranice
                            </Link>
                        </li>
                        <li>
                            <Link to='/collection/static/new' className={this.props[0].location.pathname == '/collection/static/new' ? 'active' : null}>
                                <Isvg src={add} />
                                Dodaj stranicu
                            </Link>
                        </li>

                    </ul>

{/*
                    <h6>KONTAKTI</h6>
                    <ul>
                        <li>
                            <Link to='/contacts' className={this.props[0].location.pathname == '/contacts' ? 'active' : null}>
                                <Isvg src={mail} />
                                Svi kontakti
                            </Link>
                        </li>

                    </ul>


                    <h6>PODEŠAVANJA</h6>
                    <ul>
                        <li>
                            <Link to='/config' className={this.props[0].location.pathname == '/config' ? 'active' : null}>
                                <Isvg src={settings} />
                                Informacije na sajtu
                            </Link>
                        </li>
                        <li>
                            <Link to='/seo' className={this.props[0].location.pathname == '/seo' ? 'active' : null}>
                                <Isvg src={rocket} />
                                SEO tagovi
                            </Link>
                        </li>
                    </ul>
*/}
                    <ul className="logout">
                        <li onClick={() => localStorage.removeItem('token')}>
                            <Link to='/login' >
                                <Isvg src={exit} />
                                Odjavi se
                            </Link>
                        </li>
                    </ul>

                </div>

                <div className="menu" onClick={() => this.setState({ _show: !this.state._show })}>
                    <Isvg src={menu} />
                </div>

            </div>
        )
    }

};

export default Sidebar;