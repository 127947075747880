
import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';
import {
    Container,
    Row,
    Col,
} from 'reactstrap';

import Isvg from 'react-inlinesvg';
import image from '../../../assets/svg/image.svg';
import { API_ENDPOINT } from '../../../constants';
import ReactQuill from "react-quill";

class HtmlImage extends Component {
    constructor(props) {
        super(props);
        this.selectFile = this.selectFile.bind(this);

        this.state = {

        };
    }

    selectFile(e) {
        let input = e.target;
        if (input.files && input.files[0]) {
            var reader = new FileReader();

            

            reader.onload = async (e) => {
                this.setState({
                    _loading: true
                })
                fetch(API_ENDPOINT + '/admin/upload', {
                    method: 'POST',
                    headers: {
                        Accept: 'application/json',
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${localStorage.getItem('token')}`

                    },
                    body: JSON.stringify({image: e.target.result})
                }).then((res) => res.text()).then((img) => {
                    this.props.onChange({
                        type: 'image',
                        value: img
                    })
                        this.setState({
                        _loading: null
                    })
                });
        
            }

            reader.readAsDataURL(input.files[0]);
        }
    }

    render() {
        const toolbarOptions = [
            ['bold', 'italic', 'underline', 'strike'],        // toggled buttons
            ['blockquote', 'code-block'],
            ['link', 'image', 'video', 'formula'],
            [{ 'align': [] }],
            [{ 'header': 1 }, { 'header': 2 }],               // custom button values
            [{ 'list': 'ordered'}, { 'list': 'bullet' }, { 'list': 'check' }],
            [{ 'script': 'sub'}, { 'script': 'super' }],      // superscript/subscript
            [{ 'indent': '-1'}, { 'indent': '+1' }],          // outdent/indent
            [{ 'direction': 'rtl' }],                         // text direction

            [{ 'size': ['small', false, 'large', 'huge'] }],  // custom dropdown
            [{ 'header': [1, 2, 3, 4, 5, 6, false] }],

            [{ 'color': [] }, { 'background': [] }],          // dropdown with defaults from theme


        ];
        return (
            <>
                <Col lg="12" className="input-wrap">

                    <div className="radio-wrap" onClick={() => this.props.onChange({ type: 'text', value: '' })}>
                        <div className={(this.props.value && this.props.value.type == 'text') || !this.props.value ? 'radio selected' : 'radio'}></div>
                        <span className="radio-label">Tekst</span>
                    </div>

                    <div className="radio-wrap" onClick={() => this.props.onChange({ type: 'image', value: null })}>
                        <div className={this.props.value && this.props.value.type == 'image' ? 'radio selected' : 'radio'}></div>
                        <span className="radio-label">Slika</span>
                    </div>

                </Col>
                {this.props.value && this.props.value.type == 'image' ?
                    <Col lg="12" className="input-wrap">
                        <div className="image-picker">
                            <input type="file" onChange={this.selectFile} />
                            {this.props.value && this.props.value.value ?
                                <img src={this.props.value.value} />
                                :
                                <div className="no-image">
                                    <Isvg src={image} />
                                    <span className="text">Izaberite sliku</span>
                                    {
                                        this.state._loading ?
                                        <div className="lds-ring"><div></div><div></div><div></div><div></div></div>
                                        :
                                        null
                                    }

                                </div>
                            }
                        </div>

                    </Col>

                    :
                    <Col lg="12" className="input-wrap" style={{paddingBottom:30}}>
                        <ReactQuill modules={{toolbar: toolbarOptions}} className="rich-text" style={{height: 200}} value={this.props.value.value}   onChange={(val) => {
                            this.props.onChange({
                                type: 'text',
                                value: val
                            })
                        }}/>

                    </Col>

                }




            </>
        );
    }
}

export default HtmlImage;